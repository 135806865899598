<template>
    <div :class="[{'d-block': opened}, {'d-none': !opened}]">
        <div :class="['modal', 'fade', 'show', {'d-block': opened}]" ref="taskModal" id="taskModal" tabindex="-1" aria-labelledby="taskModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content border-0">
                    <div class="modal-header p-3 ps-4 bg-soft-info">
                        <h5 class="modal-title">Tarefa</h5>
                        <button type="button" class="btn-close" @click.prevent="closeModal"></button>
                    </div>
                    <div class="modal-body p-4">
                        <div class="row g-3">
                            <div class="col-lg-4">
                                <label for="responsibleTask" class="form-label">Responsável *</label>
                                <select id="responsibleTask" class="form-control" v-model="user_id" :disabled="internalTask.status === 'Fechado'">
                                    <option :value="''">Responsável pela tarefa</option>
                                    <option v-for="(user, key) in users" :key="'user-' + key" :value="user.id">{{ user.name }}</option>
                                </select>
                            </div>
                            <div class="col-lg-4">
                                <label for="typeTask" class="form-label">Tipo *</label>
                                <select id="typeTask" class="form-control" v-model="internalTask.type" :disabled="internalTask.status === 'Fechado' || internalTask.id.length > 0">
                                    <option :value="''">Tipo de tarefa</option>
                                    <option v-for="(type, key) in types" :key="'type-' + key" :value="type.id">{{ type.name }}</option>
                                </select>
                            </div>
                            <div class="col-lg-4">
                                <label for="estimatedClosingTask" class="form-label">Data de Resolução</label>
                                <flat-pickr
                                    v-model="internalTask.estimated_closing_datetime"
                                    :config="configDateTime"
                                    :placeholder="'Data prevista para a tarefa ser concluída'"
                                    :disabled="internalTask.status === 'Fechado' || internalTask.id.length > 0"
                                    :id="'estimatedClosingTask'"
                                    class="form-control flatpickr-input"
                                />
                            </div>
                            <div class="col-lg-12">
                                <label for="descriptionTask" class="form-label">Descrição *</label>
                                <input v-model="internalTask.description" type="text" id="descriptionTask" class="form-control" placeholder="Descrição da tarefa" :disabled="internalTask.status === 'Fechado'" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="hstack gap-2 justify-content-end">
                            <button type="button" class="btn btn-light" @click.prevent="closeModal">Fechar</button>
                            <button v-if="internalTask.status !== 'Fechado' && internalTask.id.length === 0" type="submit" class="btn btn-success" id="add-btn" @click.prevent="saveTask">Adicionar</button>
                            <button v-if="internalTask.status !== 'Fechado' && internalTask.id.length > 0" type="submit" class="btn btn-success" id="add-btn" @click.prevent="saveTask">Atualizar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-backdrop fade show"></div>
    </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { overlay } from "@/state/helpers";
import { saError } from '@/helpers/global-functions';
import { getEntity, callUrl } from '@/helpers/api-apolo';

export default {
    name: 'TaskForm',
    components: {
        flatPickr
    },
    props: {
        opened: {
            type: Boolean,
            default: false
        },
        task: {
            type: Object,
            default() {
                return {
                    id: '',
                    code: '',
                    attendants: [],
                    business_id: '',
                    description: '',
                    estimated_closing_datetime: '',
                    type: '',
                    status: ''
                }
            }
        }
    },
    data () {
        return {
            configDateTime: {
                wrap: true,
                allowInput: true,
                enableTime: true,
                dateFormat: "Y-m-dTH:i:S",
                altInput: true,
                altFormat: "d/m/Y H:i"
            },
            users: [],
            types: [
                { id: 'call', name: 'Ligar' },
                { id: 'email', name: 'E-mail' },
                { id: 'meeting', name: 'Reunião' },
                { id: 'whatsapp', name: 'Whatsapp' },
                { id: 'task', name: 'Tarefa' },
                { id: 'deadline', name: 'Prazo' },
                { id: 'lunch', name: 'Almoço' }
            ],
            user_id: '',
            internalTask: {
                id: '',
                code: '',
                attendants: [],
                business_id: '',
                type: '',
                description: '',
                estimated_closing_datetime: '',
                status: ''
            }
        }
    },
    watch: {
        task: {
            handler(val) {
                this.user_id                                 = val.attendants.length > 0 ? val.attendants[0].user_id : '';
                this.internalTask.id                         = val.id;
                this.internalTask.code                       = val.code;
                this.internalTask.attendants                 = val.attendants;
                this.internalTask.business_id                = val.business_id;
                this.internalTask.type                       = val.type;
                this.internalTask.description                = val.description;
                this.internalTask.estimated_closing_datetime = val.estimated_closing_datetime;
                this.internalTask.status                     = val.status;
            },
            deep: true
        }
    },
    methods: {
        ...overlay,
        closeModal() {
            this.$emit('close');
        },
        getUsers() {
            // TODO: Aqui vai precisar buscar os atendentes associados ao pipeline, e não todos da conta!
            try {
                getEntity('user').then(data => this.users = data);
            } catch (error) {
                console.warning(error.message)
            }
        },
        async saveTask() {
            if (!this.user_id || !this.user_id.length) {
                saError('É obrigatório informar um responsável para criar uma tarefa');
                return;
            }
            if (!this.internalTask.type || !this.internalTask.type.length) {
                saError('É obrigatório informar o tipo para criar uma tarefa');
                return;
            }
            if (!this.internalTask.estimated_closing_datetime || !this.internalTask.estimated_closing_datetime.length) {
                saError('É obrigatório informar uma data de resolução para criar uma tarefa');
                return;
            }
            if (!this.internalTask.description || !this.internalTask.description.length) {
                saError('É obrigatório informar uma descrição para criar uma tarefa');
                return;
            }

            const data = {
                user_id: this.user_id,
                description: this.internalTask.description
            }

            try {
                this.changeShowOverlay({show: true});
                if (this.internalTask.id.length > 0) {
                    const updatedTask = await callUrl(data, `/business/${this.task.business_id}/task/${this.internalTask.id}`, 'put');
                    this.$emit('updated', updatedTask);
                } else {
                    data.estimated_closing_datetime = this.internalTask.estimated_closing_datetime
                    data.type                       = this.internalTask.type
                    const createdTask = await callUrl(data, `/business/${this.task.business_id}/task`, 'post');
                    this.$emit('created', createdTask);
                }
                this.changeShowOverlay({show: false});
            } catch (error) {
                this.changeShowOverlay({show: false});
                saError(error.message)
            }
        }
    },
    created() {
        this.getUsers();
    }
}
</script>