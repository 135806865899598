<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-lg-12">
                <div class="card card-h-100">
                    <div class="card-body">
                        <FullCalendar :options="calendarOptions" />
                    </div>
                </div>
            </div>
        </div>

        <TaskForm
            :opened="taskFormOpened"
            :task="activeTask"
            @updated="updateData"
            @close="taskFormOpened = false"
        />
    </Layout>
</template>

<script>
import { DateTime } from "luxon";
import appConfig from "@/../app.config";
import Layout from "@/layouts/main.vue";
import { overlay } from "@/state/helpers";
import "@fullcalendar/core/vdom";
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import { callUrl, getUserData } from '@/helpers/api-apolo';
import TaskForm from "@/components/task-form";

export default {
    page: {
        title: "Tarefas",
        meta: [{
            name: "description",
            content: appConfig.description
        }],
    },
    components: {
        Layout,
        FullCalendar,
        TaskForm
    },
    data () {
        return {
            title: "Tarefas",
            items: [
                {
                    text: "CRM",
                    href: "/"
                },
                {
                    text: "Tarefas",
                    active: true
                }
            ],
            userId: '',
            filters: {
                query: '',
                type: '',
                status: '',
                estimated_closing_date_begin: '',
                estimated_closing_date_end: ''
            },
            fetching: false,
            calendarOptions: {
                timeZone: "local",
                locales: [ ptBrLocale ],
                locale: 'pt-br',
                droppable: false,
                navLinks: false,
                plugins: [
                    dayGridPlugin,
                    bootstrapPlugin
                ],
                customButtons: {
                    myCustomButton: {
                        text: 'Versão Lista',
                        click: function() {
                            window.location.href = '/tasks';
                        }
                    }
                },
                themeSystem: "bootstrap",
                headerToolbar: {
                    left: "prev,next today",
                    center: "title",
                    right: "myCustomButton",
                },
                initialView: "dayGridMonth",
                initialEvents: [],
                events: [],
                editable: false,
                selectable: false,
                selectMirror: false,
                dayMaxEvents: true,
                weekends: true,
                datesSet: this.dateRangeChange,
                eventClick: this.openEvent
            },
            taskFormOpened: false,
            activeTask: {
                id: '',
                attendants: [],
                business_id: '',
                description: '',
                estimated_closing_datetime: '',
                type: '',
                status: ''
            }
        }
    },
    methods: {
        ...overlay,
        async fetchData() {
            if (!this.fetching) {
                this.fetching = true;
                try {
                    this.changeShowOverlay({show: true});
                    this.calendarOptions.events = [];
                    const params = [];
                    if (this.filters.query.length > 2) {
                        params.push(`query=${this.filters.query}`);
                    }
                    if (this.filters.type.length > 0) {
                        params.push(`type=${this.filters.entity}`);
                    }
                    if (this.filters.status.length > 0) {
                        params.push(`status=${this.filters.status}`);
                    }
                    if (this.filters.estimated_closing_date_begin.length > 0) {
                        params.push(`estimated_closing_date_begin=${this.filters.estimated_closing_date_begin}`);
                    }
                    if (this.filters.estimated_closing_date_end.length > 0) {
                        params.push(`estimated_closing_date_end=${this.filters.estimated_closing_date_end}`);
                    }
                    const dataM = await callUrl({}, `/user/${this.userId}/task?${params.join('&')}`, 'get');
                    if (dataM && dataM.length) {
                        this.calendarOptions.events = dataM
                            .filter(event => event?.estimated_closing_datetime?.toString()?.length > 0)
                            .map(task => this.generateDelayedProperty(task))
                            .map(event => {
                                return {
                                    ...event,
                                    title: event.description,
                                    date: new Date(event.estimated_closing_datetime),
                                    className: (event.delayed ? 'bg-soft-danger' : (event.closeToWinning ? 'bg-soft-warning' : 'bg-soft-success'))
                                };
                            });
                    }
                } catch (error) {
                    console.log(error.message);
                }
                this.changeShowOverlay({show: false});
                this.fetching = false;
            }
        },
        updateData() {
            this.taskFormOpened = false;
            this.fetchData();
        },
        async openEvent(item) {
            try {
                this.changeShowOverlay({show: true});
                const task          = await callUrl({}, `/business/${item.event._def.extendedProps.business_id}/task/${item.event._def.publicId}`, 'get');
                this.activeTask     = JSON.parse(JSON.stringify(task));
                this.taskFormOpened = true;
            } catch (error) {
                console.log(error.message);
            }
            this.changeShowOverlay({show: false});
        },
        dateRangeChange(event) {
            if (this.filters.estimated_closing_date_begin !== event.startStr && this.filters.estimated_closing_date_end !== event.endStr) {
                this.filters.estimated_closing_date_begin = event.startStr
                this.filters.estimated_closing_date_end   = event.endStr
                console.log('filters', this.filters);
                if (this.userId.length > 0) {
                    this.fetchData();
                }
            }
        },
        generateDelayedProperty(task) {
            task.delayed        = false;
            task.closeToWinning = false;
            if (task.status == 'Aberto' && task.estimated_closing_datetime) {
                task.delayed = DateTime.fromISO(task.estimated_closing_datetime) < DateTime.now();
                if (!task.delayed) {
                    task.closeToWinning = DateTime.fromISO(task.estimated_closing_datetime) < DateTime.now().plus({ days: 1 });
                }
            }
            return task;
        }
    },
    mounted() {
        const userData = getUserData();
        this.userId    = userData.user_id;
        if (this.filters.estimated_closing_date_begin.length > 0 && this.filters.estimated_closing_date_end.length > 0) {
            this.fetchData();
        }
    }
};
</script>